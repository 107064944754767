<template>
  <div>
    <vue-element-loading :active="appLoading" :is-full-screen="false" />
    <v-snackbar v-model="showSnackBar" color="#00a972" right :timeout="timeout">
      {{msg}}
      <v-btn text @click="showSnackBar = false">
        <v-icon style="color:white;">mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12 md12 pa-0>
        <v-card id="app_height" outlined tile>
          <v-layout wrap justify-space-around>
            <v-flex xs12 md12 pa-8>
              <v-layout wrap>
                <v-flex xs12 md12 text-center>
                  <span style="font-size:20px;font-weight:500;">Investors</span>
                </v-flex>
              </v-layout>
              <v-layout wrap px-2 justify-center>
                <v-flex xs12 sm12 md12>
                  <v-card-title>
                    <v-layout wrap justify-space-around align="center">
                      <v-flex xs12 sm6 md3>
                        <v-text-field
                          v-model="search"
                          append-icon="mdi-magnify"
                          color="#26af82"
                          label="Search"
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                  </v-card-title>
                  <v-data-table
                    :headers="headers"
                    :items="projects"
                    :search="search"
                    hide-default-footer
                    dense
                    :loading="appLoading"
                    loading-text="Loading... Please wait"
                    class="elevation-1"
                    color="#26af82"
                  >
                    <template v-slot:item.paymentReceived="{ item }">
                      <v-layout>
                        <v-flex>
                          <span v-if="item.paymentReceived">Received</span>
                          <span v-else>Not Received</span>
                        </v-flex>
                      </v-layout>
                    </template>
                    <template v-slot:item.kycverified="{ item }">
                      <v-layout>
                        <v-flex>
                          <span v-if="item.kycverified">Yes</span>
                          <span v-else>No</span>
                        </v-flex>
                      </v-layout>
                    </template>
                    <template v-slot:item._id="{item}">
                      <v-layout>
                        <v-flex>
                          <router-link :to="'/ProjectPledge/'+item._id">
                            <v-btn small text color="green" block class="text-capitalize">View</v-btn>
                          </router-link>
                        </v-flex>
                      </v-layout>
                    </template>
                  </v-data-table>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import VueElementLoading from "vue-element-loading";
import axios from "axios";
export default {
  props: ["id"],
  components: {
    VueElementLoading
  },
  data() {
    return {
      appLoading: false,
      appConfirmation: false,
      showSnackBar: false,
      timeout: 3000,
      msg: null,
      search: null,
      headers: [
        {
          text: "Name",
          align: "left",
          sortable: true,
          value: "user.username"
        },
        { text: "Investment", align: "center", value: "totalInvestment" },
        {
          text: "Value at Maturity",
          align: "center",
          value: "totalValueAtMaturity"
        },
        { text: "Status", align: "center", value: "pledgestatus" },
        { text: "Payment", align: "center", value: "paymentReceived" },
        { text: "KYC Verified", align: "center", value: "kycverified" },
        { text: "Action", align: "center", value: "_id" }
      ],
      projects: []
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/projectpledge/getlist",
        params: {
          id: this.id
        },
        headers: {
          "x-auth-token": localStorage.getItem("token")
        }
      })
        .then(response => {
          this.appLoading = false;
          this.projects = response.data.data;
          this.totalpages = response.data.pages;
        })
        .catch(err => {
          this.appLoading = false;
          this.msg = err.response.data.msg;
          this.msg = "Server Unreachable";
          this.showSnackBar = true;
        });
    }
  }
};
</script>
<style>
@media only screen and (min-width: 800px) {
  #app_height {
    min-height: 80vh;
    min-width: 80vw;
  }
}
.centered-input input {
  text-align: center;
  height: 20px;
}
</style>